<script setup lang="ts">
const emits = defineEmits(['update:modelValue', 'target-click'])
const props = withDefaults(
  defineProps<{
    modelValue?: boolean
    text?: string
    variant?: 'secondary' | 'beige'
  }>(),
  {
    modelValue: false,
    text: undefined,
    variant: 'beige',
  },
)

const deepLink = computed(() => {
  const config = useRuntimeConfig()

  return config.public.deepLinkOwnerApp
})

const isSecondary = computed(() => props.variant === 'secondary')
const isBeige = computed(() => props.variant === 'beige')
</script>

<template>
  <transition name="fade">
    <div
      v-if="modelValue"
      :class="[
        'flex flex-1 justify-between p-4 text-left lg:text-center text-md',
        { 'bg-beige': isBeige },
        { 'bg-primary-100': isSecondary },
      ]"
    >
      <i18n-t class="w-4/5 lg:w-full mb-0" :keypath="text" tag="p">
        <template #link>
          <base-button
            class="ml-1.5 cursor-pointer !underline"
            :href="deepLink"
            target="_blank"
            variant="link"
            @click="emits('target-click')"
          >
            {{ $t('global.open') }}
          </base-button>
        </template>
      </i18n-t>
      <BaseIcon
        class="mt-0.5 lg:mt-0 cursor-pointer"
        name="cancel"
        @click="emits('update:modelValue', false)"
      />
    </div>
  </transition>
</template>
<style scoped>
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-leave-to {
  opacity: 0;
}
</style>
